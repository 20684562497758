.PriceCodePage-initializing {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    background: green;
    height: 100%;

    .spinner::after {
        background: white;
    }
}

.PriceCodePage {
    margin-bottom: 20px !important;

    > h1 {
        text-align: center;
    }

    > p {
        max-width: 650px;
        margin: auto;
        padding-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.PriceCodePage-priceCode {
  margin: 20px auto auto;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  >input {
    margin-top: 5px;
  }
  >a {
    margin-top: 20px;
    align-self: flex-end;
  }
  >label:nth-of-type(2) {
      margin-top: 10px;
  }
}

.PriceCodePage-links {
  margin: 20px auto auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  >*:first-child {
    margin-left: 0 !important;
  }
  >* {
    margin-left: 20px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  label {
    font-family: 'Open Sans Condensed';
    font-size: 24px;
    letter-spacing: 0.05em;
    font-weight: normal;
    text-transform: uppercase;
  }
  img {
    width: 170px;
    height: 170px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  p {
    max-width: 250px;
    margin-bottom: 20px;
    min-height: 80px;
  }
}


.PriceCodePage-links-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media(max-width: 870px) {
  .PriceCodePage-links {
    flex-direction: column !important;
    a {
      width: 70%;
      text-align: center;
      margin-bottom: 20px;
    }
    img {
      width: 80px !important;
      height: 80px !important;
      margin-right: 20px;
    }
    p {
      padding-right: 10px;
    }
  }
  .PriceCodePage-links-main {
    flex-direction: row !important;
  }
}