.MaintentanceMessage {
    background: #f0d077;
    width: calc(100vw);
    max-width: unset !important;
    > div {
        padding: 10px;
        max-width: 1000px;
        margin: auto;
        text-align: center;
        >img {
            margin-right: 10px;
        }
        >span {
            display: inline-block;
            transform: translateY(-15px);
        }
    }
}

@media(max-width: 892px) {
    .MaintentanceMessage {
        > div {
            > img {
                margin-bottom: 20px;
            }
        }
    }
}
