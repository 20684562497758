@import './Colors.scss';

.App-footer {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  background-color: $headerback;
  color: $footertext;
  >div {
    max-width: 1000px;
    margin: auto;
    padding-top: 15px;
    display: flex;
    flex-direction: row;

    >div:last-child {
      margin-left: 20px;
      width: 500px;
    }

    p {
      font-size: .8em !important;
    }

    a.chromeless {
      color: white;
      font-size: .8em !important;
    }

    iframe {
      margin-left: 24px;
    }
  }
}