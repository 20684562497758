@import './Colors.scss';

.InstitutionLookupCtl {
    display: flex;
    flex-direction: column;
    position: relative;

    input {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4QEEFh4P/5sh7AAAAKpJREFUOMvt0T0OQUEYheGHiEZhBRqdDViBhELUYgcqP4lVSG7c6m5BLRoisQydPWgUKoWRTMZVaZ3qm2/O+xYz/PNzKvFhOZuuMccJ4ywvbtFdE1v0sMnyYgXVRLhADQMcA/SGj2FfCz1lgn00d4OkFeBuWS8VTHBOJJcEPofepyDLizuGOETrRjQfMAy9z0eMHqyOHfoJPMry4vH1F0okF7RxRSeF/3nlCXWvLD1yuEiVAAAAAElFTkSuQmCC) right 10px center no-repeat #ffffff;
        padding-right: 24px;
    }

    div {
        position: absolute;
        top: 38px;
        left: 6px;
        width: calc(100% - 12px);
        max-height: 200px;
        overflow-y: auto;
        border: 1px solid #cac8c0;
        background-color: #fff;
        z-index: 1;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                padding: 2px;

                &:hover {
                    background-color: #fdb813;
                    color: #ffffff;
                }

                &.focus {
                    background-color: #f0a31c;
                    color: #ffffff;
                }
            }
        }
    }
}