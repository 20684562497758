@use "react-widgets/scss/styles.scss" with ( $components: ( 'Listbox', 'DropdownList', 'Combobox', 'Multiselect',
// 'DatePicker',
// 'Calendar',
// 'TimeInput',
'NumberPicker' ) );

.rw-widget-input {
    border: 0 !important;
    outline: 0 !important;
    box-shadow: unset !important;
}

@import './Colors.scss';

@import url('https://fonts.googleapis.com/css?family=Abril+Fatface');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700,400italic,700italic');
@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700');

h1, h2, h3, h4, h5, h6 {
    font-family: 'Abril Fatface',serif;
    font-size: 20pt;
    margin-top: 10px;
    margin-bottom: 0px;
    font-weight: normal;
    line-height: 28pt;
    letter-spacing: 1px;
    color: $titleText;
}

@media (min-width: 640px) {
    h1 {
        margin-bottom: 5px;
        line-height: 38pt;
    }
}

@media (min-width: 767px) {
    h1 {
        font-size: 38pt;
        /*letter-spacing: 2px;*/
        margin-top: 60px;
        margin-bottom: 10px;
        line-height: 38pt;
    }
}

html {
    width: 100%;
}

body {
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    color: $bodytext;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 10.5pt;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    font-size: 12pt;
    font-weight: bold;
    display: inline-block;
    line-height: 32px;
    border-radius: 10px;
    outline: 0;
    white-space: nowrap;
    background-color: $buttonback;
    border: 1px solid $buttonborder;
    color: $buttontext;
    padding: 0 24px 0 24px;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
}

a:hover {
    background-color: $buttonborder;
}

a:active:hover {
    background-color: lighten($buttonback, 10%);
}

a.light {
    background: $buttonback_light;
    color: $buttontext_light;
    border-color: $buttonborder_light !important;
    font-weight: normal;
}

a.light:hover {
    background: $buttonback_light;
    color: $buttontext_light;
    border-color: $blackish !important;
    font-weight: normal;
}

a.reverse {
    background: $buttonback_light;
    color: $buttonborder_light;
    border-color: $buttonborder_light !important;
}

a.reverse:hover {
    background: $buttonback;
    color: $buttontext;
    border-color: $buttontext !important;
    ;
}

a.chromeless {
    background: none !important;
    color: $bodytext;
    border: 0 solid transparent !important;
    padding: 0;
    margin: 0;
    line-height: unset !important;
}

a.chromeless:hover {
    text-decoration: underline;
}

a.underlinedlink {
    font-weight: normal;
    text-decoration: underline;
    color: darken($graytext, 25) !important;
    @extend a, .chromeless;
}

a.underlinedlink:hover {
    color: $testoutyellow !important;
}

label {
    font-size: 0.85em;
    font-weight: 700;
}

.radio > label, .checkbox > label {
    font-size: 10.5pt !important;
    font-weight: normal !important;
}

select {
    height: 38px;
    padding-right: 35px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4QEEFh4P/5sh7AAAAKpJREFUOMvt0T0OQUEYheGHiEZhBRqdDViBhELUYgcqP4lVSG7c6m5BLRoisQydPWgUKoWRTMZVaZ3qm2/O+xYz/PNzKvFhOZuuMccJ4ywvbtFdE1v0sMnyYgXVRLhADQMcA/SGj2FfCz1lgn00d4OkFeBuWS8VTHBOJJcEPofepyDLizuGOETrRjQfMAy9z0eMHqyOHfoJPMry4vH1F0okF7RxRSeF/3nlCXWvLD1yuEiVAAAAAElFTkSuQmCC) right 10px center no-repeat #ffffff;
    -webkit-appearance: none;
    -moz-appearance: none;
}

select::-ms-expand {
    display: none;
}

input[type=text] {
    box-sizing: unset !important;
    border: 1px solid #cac8c0;
    border-radius: 5px;
    height: 20px;
    padding: 8px 15px;
    vertical-align: top;
    background: #ffffff;
    font-size: 10.5pt;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=text], select {
    border: 1px solid #cac8c0;
    border-radius: 5px;
    padding: 8px 15px;
    outline: none;
    font-size: 10.5pt;
}

input[type=text]:disabled, select:disabled {
    background-color: unset !important;
}

input[type='text']:focus,
input[type='email']:focus,
input[type='password']:focus,
select:focus {
    border-color: #f0a31c;
}

#modalroot {
    height: 100%;
}

.dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div {
        background: white;
        color: $bodytext;
        min-width: 400px;
        border-radius: 5px;
        /* Close Button and Title */
        > div:nth-child(1) {
            display: flex;
            flex-direction: column;
            font-weight: 700;
            /* Close Button */
            > *:nth-child(1) {
                align-self: flex-end;
                font-size: 20px;
                font-weight: 400 !important;
                margin: 0 8px 0 0;
                padding: 0;
                cursor: pointer;
            }

            > *:nth-child(1):hover {
                color: $linkhover;
            }
            /* Title */
            > *:nth-child(2) {
                align-self: center;
                font-size: 32px;
                font-weight: 100 !important;
            }
        }
        /* Content */
        > div {
            margin: 20px;
            max-height: calc(100vh - 300px);
            overflow-y: auto;
        }
    }
}

.App {
    background-color: $bodyback;
}

.App-body {
    min-height: calc(100vh - 196px);
    color: $bodytext;

    > div {
        max-width: 1000px;
        margin: auto;
    }
}

.App-form {
    background-color: $formback;
    border-radius: 10px;
    padding: 20px;
    color: #726f69;
}

.red {
    color: red;
}

.gray {
    color: darken($graytext, 25) !important;
}

.bold {
    font-weight: bold;
}

.underline {
    text-decoration: underline;
}

.italic {
    font-style: italic;
}

.capitalize {
    text-transform: capitalize;
}

.form-spacer {
    height: 15px;
}

.invalid {
    border: 1px solid red !important;
}

.invalid:focus {
    border-top-color: #f0a31c !important;
    border-left-color: #f0a31c !important;
}

.validationMessage {
    border: #ff0000 1px solid;
    border-width: 1px 1px 1px 5px;
    background-color: #f9f4ee;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    a { 
        font-size: unset !important;
        font-weight: bold;
    }
}

input[type='checkbox'] {
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    border: none;
    opacity: 0;
}

input[type='checkbox'] + label::before {
    content: ' ';
    display: inline-block;
    width: 19px;
    height: 19px;
    border: 1px solid transparent;
    vertical-align: text-bottom;
    margin-right: 5px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjUwOEM2MUIxRDFGQzExRTZCMDAxRkJCQUJBQTY1NkNFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjUwOEM2MUIyRDFGQzExRTZCMDAxRkJCQUJBQTY1NkNFIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NTA4QzYxQUZEMUZDMTFFNkIwMDFGQkJBQkFBNjU2Q0UiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NTA4QzYxQjBEMUZDMTFFNkIwMDFGQkJBQkFBNjU2Q0UiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7QYjcqAAAAx0lEQVR42uyUMQ7CMBAEHTsN6aBNKKDlCzQ0CPgFBXyHfyCoAw1PARISSlLhNLbDrkRPZNHBSquTrNvR2dI5mE0nAurAQ7gLh+KzDFzB5/R4qgUgETzOsuvWGPNsWoh97GeOeUJGONg1HmKOeckrxHEyFx5Kkv4CpUdIqJSKfCBSSr6lkuIL+kN+AmKstdon7JyrUSwh1b0sDj6QsrilKA9CLuvVcpPn2R5k3XICzX7mmA/e/wl3Z8Bl4i604FhOQAD+E/0SYACWqKg9nC5EwwAAAABJRU5ErkJggg==) center no-repeat;
    transform: translateY(2px);
}

input[type='checkbox']:checked + label::before {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkMxNDBEQzY4RDFGQjExRTZBMkMzQjkzQzJCQTg3QkU2IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkMxNDBEQzY5RDFGQjExRTZBMkMzQjkzQzJCQTg3QkU2Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QzE0MERDNjZEMUZCMTFFNkEyQzNCOTNDMkJBODdCRTYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QzE0MERDNjdEMUZCMTFFNkEyQzNCOTNDMkJBODdCRTYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5dohrkAAABKklEQVR42mL8u0OYAQhUgbgDiF2AmI+BMPgExHuAuAKIbzMCDVEHMk4AsQAD6eADEFswAYk2Mg1ggOprA7nkI5FewAW+MFFoAAjwMBGtlIWPgcl8OwOTzXEMKeIMYeZkYDJeBQwBUwaG//+IMASkkEcTxQBGo+VAcRMGhu8PGf6dDcV0JIapphvBtv07EwhMDRcZGPXnMjAK2TAw/HgOFAsB0s8Iu+T/02VA2znAzmc0XsnAKOrGwPDrLcTQb/ex+hbTkOvlDP+frwW6kRfoAlsGht8fIAZ8vYM7zDFE/v9l+H85C2g8O9AQa2AYRDAwfL6GN9xBie0/hekE7J1PFJoBTrF7KDRkF8iQamhuZCAzF1eDDLkBys5AvA6IPxOp+TNUPUjfDYAAAwD+OFPXdst01wAAAABJRU5ErkJggg==) center no-repeat;
}

input[type='checkbox']:focus + label::before {
    border-color: #f0a31c;
}

input[type='radio'] {
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    border: none;
    opacity: 0;
}

input[type='radio'] + label::before {
    content: ' ';
    display: inline-block;
    width: 19px;
    height: 19px;
    border: 1px solid transparent;
    vertical-align: text-bottom;
    margin-right: 5px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjdGMDUyRTE1RDFGQTExRTZCOTVEQkVENDIwNzEwOTVCIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjdGMDUyRTE2RDFGQTExRTZCOTVEQkVENDIwNzEwOTVCIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6N0YwNTJFMTNEMUZBMTFFNkI5NURCRUQ0MjA3MTA5NUIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6N0YwNTJFMTREMUZBMTFFNkI5NURCRUQ0MjA3MTA5NUIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4dse5lAAABXElEQVR42mL8//8/AwwUF2QxAalwII4EYgsgFgXi10B8AoiXA/HK3gnT/sHUM8I0AzUqAalVhkYmxobGpgzy8ooMPDw8DF++fGF4+PA+w/mzpxnOnztzFqgmDGjAPbhmqMbjvn5BYg5OLgy4wIF9exg2b1r3Csi0BBnA/PnDK5BTdwI1quLTCAIKikoM7Gzs3LduXrfetWPrXJDGCCNjUxNCGmEApA6kHqQPpDnW2MScgRQAVR8L0mwiKydHkmYpaWkQpQ/SLMLNzUOSZm5ubhAlDNL85uvXLyRp/vr1K4h6C9J85vGjRyRpfvb0KYi6CNK8+OyZkyRphqpfDNK84tzZ02cO7N9DlEaQOpB6kD4maFqN2Lxx3StCBoDkQepA6kH6kNO2Mijhw9K2rIwcAzcPNzhwHj9+xHDh3BmQjaC0HQ7UeBclY2DJVcagaASFKihQQc4EYeRcBRBgAHy5kvl4DrGzAAAAAElFTkSuQmCC) center no-repeat;
    transform: translateY(1px);
}

input[type='radio']:checked + label::before {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjcxNjBFQzJCRDFGQTExRTY5RjMyQUM2MEZGQjE3NURGIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjcxNjBFQzJDRDFGQTExRTY5RjMyQUM2MEZGQjE3NURGIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NzE2MEVDMjlEMUZBMTFFNjlGMzJBQzYwRkZCMTc1REYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NzE2MEVDMkFEMUZBMTFFNjlGMzJBQzYwRkZCMTc1REYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5VLwMMAAAB+UlEQVR42nyTy0sbURTGvzHQqcxAhT7ApIlG0S5cFJ1J1XbTSneiggUfC5duupCA/4D7Im0pdNdVFppSClYKbbHVbvrQSdSFiIJGE5yAtSg0o7bFXM+5kxEL6Vz4+O7j/O6ce+dcRQgBr43GH1aQ9ZMGSW2kq6QfpG+kCVJy/MnzoheveDCBdWQvm1tMo9mIoaYmCl3XUSgUsL2dwWJqAYtpK0UxfbTB5hlcAr92dfdeu9txH/9rc59mMP3m9S5123mDwK+DXU71PYENEjw5htgch1iJQ6yPQdiU7d99KJdM1NY3Qr2gautrq3c+vHv7gsGBFiNmuuARilYvxMYj4HALKP6RzmOe53WO43jmGB4yzFaZlsg8BQ7my+dM8yLzTHZL8UMMm+FIxIXzr+DXhJ2UHgyF2G4yfEXTdHf12PaF8TsvTdM0tssM7zlOwV28GPSH1WppjuOw/WTYymWz7k+vfuDLKsE+6fbODtsyw4mU9d1djMaBqlvlSZpXoiOyW4pPBNrbYiv5vN2pqmqwtu6G+3UlQOej858cApVhKJFhVDQ9BgKVmJudwefZjxbBca/C6mnwpauHKuyeT4UROD0lK+w2VdjG+drmDZJebYevR6DpmrycXC6LpbSFdGqBa7ufwX8eRplXZfBv5FvlSyVNss6/qlMBBgCpM8oVxoJSDAAAAABJRU5ErkJggg==) center no-repeat;
    transform: translateY(1px);
}

input[type='radio']:focus + label::before {
    border-color: #f0a31c;
}

.spinner {
    margin: 0 auto;
    text-indent: -9999em;
    font-size: 10.5pt;
    width: 4em;
    height: 4em;
    border-radius: 50%;
    background: #fdb813;
    background: -moz-linear-gradient(left, #fdb813 10%, rgba(253, 184, 19, 0) 42%);
    background: -webkit-linear-gradient(left, #fdb813 10%, rgba(253, 184, 19, 0) 42%);
    background: -o-linear-gradient(left, #fdb813 10%, rgba(253, 184, 19, 0) 42%);
    background: -ms-linear-gradient(left, #fdb813 10%, rgba(253, 184, 19, 0) 42%);
    background: linear-gradient(to right, #fdb813 10%, rgba(253, 184, 19, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.spinner::before {
    width: 50%;
    height: 50%;
    background: #fdb813;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}

.spinner::after {
    background: #f5f3ea;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


input.inputOverlay {
    padding-right: 35px;
}

input.inputOverlay.match {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAUCAYAAACTQC2+AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4AweFiw6SDARUQAAAaRJREFUSMet1D1rFUEUxvGfaxREsLKTVAqpLKxkC/ESJRKNQTSmEFHErcQP4BewsAo2ohgsxBcELRJTiJCbqQyJrWIlFtopigFfiK/NLCzXO3tvzJ5unnPO/AfmnGfDzWf7rSeKPPyjTS+2juMqdqCN0wMajunF1iTuI4vSAdzKGoaM404FUsahrEHIKB5iU5f0p6whyDAeJSBwOWsAsg9z2JIouVLkYWq9oL09INeKPFyCcuqGcBBv8AS/+4DswVNsS+Sv42J5GMAJPMDGqM1FbbUGsrsH5B4uFHn4UwoZpioQGIvTszlxyVBcwu01kDNVSAka7FJ8FHc7HgC7MF8DeYyzRR5+dSYyPE80TeB2BTaIEG0lBZko8vCzWzLDOXxMNJ/CjQhZqIEsYLLIQ/JfM7zEYawkas7jFXbWQMaKPHyvG9Fyj5Ywgi+Juq0JfRnHijx87bUL1YVdwji+9bmsyxgp8rDST3GnM7TjeK/26HuBI/jcr4V0s6A2TuJHDWQYH9biVSmvm42wTit6jVG8X6sp1pnqTLSit/E8jxbe/Y/7/gWKxmKU179pOAAAAABJRU5ErkJggg==) right 10px center / 16px auto no-repeat #ffffff;
}

.InitializingBodyPage {
    display: flex;
    flex-direction: column;
    justify-items: center;

    .spinner::after {
        background: white;
    }
}

.iframed {
    text-align: center;
}