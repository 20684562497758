@import './Colors.scss';

.App-header {
  background-color: $headerback;
  color: white;
  >div {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 64px;
  }
}