@import './Colors.scss';

.CheckoutPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  >ol {
    width: 100%;
  }
  h3::after {
    content: ' ';
    display: block;
    width: 60px;
    height: 0;
    border-bottom: 2px solid #fdb813;
    margin-bottom: 15px;
  }
}

.CheckoutPage-columns {
    display: flex;
    flex-direction: row;
    max-width: 900px;
    margin-bottom: 20px;

    > div:first-of-type {
        background-color: $formback;
        border-radius: 5px;

        > div {
            margin-left: 25px;
            margin-right: 25px;
            display: flex;
            flex-direction: column;

            > * {
                margin-top: 5px;
            }
        }

        > div:last-of-type {
            margin-bottom: 25px;
        }
    }

    > div {
        width: 50%;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .checkbox {
        label {
            font-weight: normal;

            > a {
                font-size: inherit !important;
                font-weight: normal !important;
                text-decoration: underline;
            }

            > a:hover {
                color: $testoutyellow;
            }
        }
    }

    p {
        font-size: 10.5pt;
        color: #726f69 !important;

        a {
            font-size: 10.5pt !important;
            font-weight: normal !important;
        }

        a:hover {
            text-decoration: underline;
        }
    }

    p.tick::before {
        content: ' ';
        display: block;
        width: 15px;
        height: 0;
        border-bottom: 1px solid #fdb813;
        padding-bottom: 6px;
        transform: translateX(-26px) translateY(10px);
    }

    .CheckoutPage-tip {
        display: block;
        position: relative;
        height: 0;
        transform: translateX(450px) translateY(-15px);
    }

    .CheckoutPage-shippingTip {
        font-weight: normal;
        color: #726f69;
        text-align: right;
        background: #f1efeb;
        border: 1px solid #f1efeb;
        border-radius: 5px;
        padding: 7px 9px;
    }

    .CheckoutPage-shippingProvider {
        max-width: unset;
        display: flex;
        flex-direction: row;
        line-height: 25px;
        > span {
            flex-grow: 1;
        }
        > span:nth-child(2) {
            text-align: right;
            font-weight: 700;
        }
    }

    > div > div > div:not(.InstitutionLookupCtl,.CheckoutPage-tip) {
        display: flex;
        flex-direction: row;
        max-width: calc(100vw - 37px);

        > div {
            display: flex;
            flex-grow: 1;
            flex-shrink: 1;
            flex-direction: column;
            max-width: calc(50% - 5px);

            input {
                margin-top: 5px;
                display: block;
            }

            select {
                display: block;
                margin-top: 5px;
            }
        }

        > div:nth-child(1) {
            margin-right: 5px;
        }

        > div:nth-child(2) {
            margin-left: 5px;
        }
    }

    > div > div > label, > div > div > div > div > label {
        font-size: 0.85em;
    }
}

.CheckoutPage-terms {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;

  li {
    margin-top: 10px;
  }

  li>ol {
    list-style-type: lower-alpha;
  }

  >a:first-of-type {
    margin-top: 20px;
    align-self: flex-end;
  }

  >div:first-of-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    >div {
      margin-top: 30px;
    }
    >div::after {
      content: ' ';
      display: block;
      bottom: 0;
      width: 100px;
      height: 0;
      border-bottom: 2px solid #fbd813;
      transform: translateX(50px);
      margin-top: 5px;
    }
    >p {
      text-align: center;
      max-width: 500px;
    }
  }

  >div.CheckoutPage-close:last-of-type {
    display: flex;
    flex-direction: row-reverse;
  }
}

.CheckoutPage-total {
    display: flex !important;
    flex-direction: row-reverse !important;
    align-items: flex-end;
    font-size: 12pt;
    margin-top: 0 !important;
    > span:last-of-type {
        font-size: 9pt;
        margin-right: 10px;
        display: inline-block;
        transform: translateY(-2px);
    }

    > span:first-of-type {
        font-weight: 700;
    }
}

.CheckoutPage-total.first {
  margin-top: 30px !important;
  border-top: 1px solid $formseparator;
  padding-top: 20px;
}

.CheckoutPage-total.last {
  padding-top: 5px;
  >span:first-of-type {
    font-size: 16pt;
    font-weight: 700;
  }
}

.CheckoutPage-links {
  display: flex !important;
  flex-direction: row-reverse !important;
  align-items: flex-end;
  margin-top: 20px !important;
  a {
    align-self: center;
  }
  a.chromeless {
    margin-right: 15px;
    font-weight: normal;
    text-decoration: underline;
  }
}

ol.CheckoutPage-progress {
  list-style: none;
  margin: 0;
  padding: 38px 0 20px 0;
  overflow: hidden;
  font-size: 0;
}
ol.CheckoutPage-progress li::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  height: 1em;
  width: 100%;
  border-bottom: 2px solid #726f69;
}
ol.CheckoutPage-progress li:first-of-type::before {
  left: 50%;
  width: 50%;
}
ol.CheckoutPage-progress li:last-of-type::before {
  width: 50%;
}
ol.CheckoutPage-progress li {
  display: inline-block;
  position: relative;
  list-style-image: none;
  list-style-type: none;
  font-size: 12pt; /* 1.2em */
  width: 33%;
  margin: 0;
  padding: 0;
  color: #726f69;
  text-align: center;
}
ol.CheckoutPage-progress li span img {
  display: none;
}
ol.CheckoutPage-progress li span::before {
  content: ' ';
  display: block;
  margin: 0 auto 1em auto;
  padding: 0.15em;
  width: 2em;
  height: 2em;
  line-height: 1.5em;
  border: 2px solid #726f69;
  border-radius: 50%;
  background: #ffffff;
  font-weight: bold;
  box-sizing: border-box;
}
ol.CheckoutPage-progress li.CheckoutPage-progress-current span::before {
  background: #726f69;
  color: #ffffff;
}
ol.CheckoutPage-progress li.CheckoutPage-progress-current:nth-of-type(1) span::before {
  content: '1';
}
ol.CheckoutPage-progress li.CheckoutPage-progress-current:nth-of-type(2) span::before {
  content: '2';
}
ol.CheckoutPage-progress li.CheckoutPage-progress-current:nth-of-type(3) span::before {
  content: '3';
}
ol.CheckoutPage-progress li.complete span::before {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAUCAYAAACTQC2+AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4AweFiw6SDARUQAAAaRJREFUSMet1D1rFUEUxvGfaxREsLKTVAqpLKxkC/ESJRKNQTSmEFHErcQP4BewsAo2ohgsxBcELRJTiJCbqQyJrWIlFtopigFfiK/NLCzXO3tvzJ5unnPO/AfmnGfDzWf7rSeKPPyjTS+2juMqdqCN0wMajunF1iTuI4vSAdzKGoaM404FUsahrEHIKB5iU5f0p6whyDAeJSBwOWsAsg9z2JIouVLkYWq9oL09INeKPFyCcuqGcBBv8AS/+4DswVNsS+Sv42J5GMAJPMDGqM1FbbUGsrsH5B4uFHn4UwoZpioQGIvTszlxyVBcwu01kDNVSAka7FJ8FHc7HgC7MF8DeYyzRR5+dSYyPE80TeB2BTaIEG0lBZko8vCzWzLDOXxMNJ/CjQhZqIEsYLLIQ/JfM7zEYawkas7jFXbWQMaKPHyvG9Fyj5Ywgi+Juq0JfRnHijx87bUL1YVdwji+9bmsyxgp8rDST3GnM7TjeK/26HuBI/jcr4V0s6A2TuJHDWQYH9biVSmvm42wTit6jVG8X6sp1pnqTLSit/E8jxbe/Y/7/gWKxmKU179pOAAAAABJRU5ErkJggg==) center / 1em no-repeat #ffffff;
  border-color: #9bc63f;
}

ol.CheckoutPage-progress li span {
  position: relative;
}

@media(max-width: 1000px) {
  p.tick::before {
    display: none !important;
  }
  .CheckoutPage-tip {
    display: block !important;
    position: unset !important;
    height: unset !important;
    transform: unset !important;
  }
  .CheckoutPage-columns>div {
    width: calc(100vw) !important;
    margin-left: 0px !important;
  }
  .CheckoutPage-columns>div:nth-child(2) {
    display: none !important;
  }
}

.CheckoutPage-inputToggle {
    align-self: flex-end;
    width: 16px;
    height: 16px;
    margin-top: -16px !important;
    transform: translateX(-11px) translateY(-10px);
}

.CheckoutPage-generatedField {
    text-transform: capitalize;
}