$titleText: #484641;
$bodytext: rgb(114, 111, 105);
$blackish: #393d46;

$headerback: #484641;
$footerback: #484641;
$footertext: #a0a0a0;
$bodyback: white;
$linkhover: rgb(24, 128, 224);
$formback: #f5f3ea;

$buttonback: #fdb813;
$buttonborder: #f0a31c;
$buttontext: white;

$buttonback_light: white;
$buttonborder_light: #f0a31c;
$buttontext_light: $blackish;

$rowalternate: rgb(251, 250, 249);
$rowseparator: rgb(224, 224, 224);

$testoutyellow: rgb(253, 184, 19);
$graytext: #c1beb7;

$formseparator: #cac8c0;