@import './Colors.scss';

.ProductsPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px !important;

    > h1 {
        text-align: center;
    }

    > p {
        max-width: 550px;
        margin: auto;
        text-align: center;
    }

    > p:first-of-type {
        padding-top: 20px;
    }

    > p:last-of-type {
        padding-bottom: 20px;
    }
}

.ProductsPage-table {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    width: calc(100% - 20px);

    > .ProductsPage-tableRow:nth-child(odd) {
        background-color: $rowalternate;
        border-top: 1px solid $rowseparator;
        border-bottom: 1px solid $rowseparator;
    }

    > .ProductsPage-tableRow:last-child {
        border-bottom: 1px solid $rowseparator;
    }
}

.ProductsPage-tableRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    height: 85px;

    > *:nth-child(1) {
        flex-grow: 1;
    }

    > span:nth-child(2) {
        flex-grow: 0;
        width: 75px;
    }

    > span:nth-child(3) {
        flex-grow: 0;
        width: 150px;
    }
}

.ProductsPage-productTitle {
    display: flex;
    flex-direction: row;

    > *:first-child {
        margin-right: 10px;
    }

    > div:nth-child(2) > div {
        display: flex;
        flex-direction: row;

        > *:nth-child(1) {
            color: $testoutyellow;
            margin-right: 10px;
            font-size: 12px;
        }

        > *:nth-child(2) {
            color: $graytext;
            font-size: 12px;
        }
    }
}

.ProductsPage-tableHeader {
    height: 35px;
    border: unset !important;
    border-radius: 5px;
    background-color: $formback !important;
    margin-bottom: 10px;
    @extend .ProductsPage-tableRow
}

.ProductsPage-links {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    align-self: flex-end;
    margin-top: 50px;
    margin-right: 50px;

    > * {
        display: inline-block;
        margin-left: 5px !important;
    }

    > a:first-of-type {
        margin-left: 25px !important;
    }
}

.ProductsPage-smallScreenPrice {
    display: none !important;
}

.ProductsPage-largeScreenPrice {
}

@media(max-width: 650px) {
    .ProductsPage-tableHeader {
        display: none;
    }

    .ProductsPage-tableRow {
        height: 120px !important;

        .ProductsPage-productTitle {
            display: flex !important;
            flex-direction: column !important;

            > div:first-of-type {
                overflow: visible;
                white-space: nowrap;
                max-width: 100px;
            }
        }

        img {
            visibility: hidden;
            width: 0px;
            height: 0px;
        }

        a {
            transform: translateY(18px);
        }
    }

    .ProductsPage-tableRow:nth-child(2) {
        border-top: 1px solid $rowseparator !important;
    }

    .ProductsPage-smallScreenPrice {
        display: block !important;
        text-align: end;
        padding-right: 15px !important;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .ProductsPage-largeScreenPrice {
        visibility: hidden !important;
        width: 0px !important;
        height: 0px !important;
    }
}
