@import './Colors.scss';

.ReceiptPage {
    margin-bottom: 20px !important;

  h3 {
    padding-top: 15px;
  }
  .CheckoutPage-columns {
    >div>div>div {
      display: block;
      >div {
        margin: 0;
        max-width: unset;
        display: block;
        text-align: center;
      }
    }
  }
}

.ReceiptPage-Codes {
  margin-bottom: 35px;

  .ReceiptPage-Activation {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 35px;
    >span>span:nth-child(1) {
      font-size: 1.5em;
      font-weight: bold;
    }
    a {
      margin-left: 4px;
      font-size: 9pt;
      font-weight: normal;
      padding: 5px;
      line-height: 14px;
      transform: translateY(-4px);
    }
    >span:nth-child(2) {
      font-size: 0.75em;
      color: #c1beb7;
    }
  }
}

.ReceiptPage-Receipt {
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid $formseparator;
  >div {
    margin-left: 20px !important;
    margin-right: 20px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    text-align: left !important;
  }
  >div:nth-child(1) {
    /*TestOut Address*/
    >p{
      font-size: 8pt !important;
    }
  }
  >div:nth-child(2) {
    /*Date/Terms*/
    display: flex !important;
    flex-direction: row !important;
    font-size: 12px !important;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid $formseparator;
    >span>span {
      font-weight: normal;
    }
    >span:nth-child(1) {
      flex-grow: 1;
    }
  }
  >div:nth-child(3) {
    /*Order Number*/
    display: flex !important;
    flex-direction: column !important;
    padding-bottom: 5px;
    border-bottom: 1px solid $formseparator;
    >span:nth-child(2) {
      font-weight: bold;
      font-size: 21px;
    }
  }
  >div:nth-child(4) {
    /*Billing/Shipping Addresses*/
    display: flex !important;
    flex-direction: row !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    >div {
      flex-grow: 1;
      flex-shrink: 1;
      display: flex !important;
      flex-direction: column !important;
      >span {
        font-size: 14px !important;
      }
      >span:nth-child(1) {
        font-weight: bold;
        padding-bottom: 5px;
      }
      >span:nth-child(3) {
        display: flex;
        flex-direction: column;
      }
    }
  }
  >div:nth-child(5) {
    /*Delivery*/
    >div:nth-child(1)>span:nth-child(1) {
      font-weight: bold !important;
    }
  }
  >div:nth-child(7) {
    border-top: 1px solid $formseparator;
    font-family: 'Abril Fatface', serif;
    font-size: 2em;
    text-align: center !important;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.ReceiptPage-ReceiptTable {
  table {
    border-collapse: separate;
    border-spacing: 0;
  }
  th {
    text-transform: none;
  }
  th, td {
    padding: 0;
  }
  td {
    vertical-align: top;
  }
  table th.stretch, table td.stretch {
    width: 99%;
    white-space: normal;
  }
  .left {
    text-align: left !important;
  }
  tr th:first-of-type, tr td:first-of-type {
    padding-left: 0;
  }
  table th {
    text-align: right;
    font-weight: bold;
    font-size: 9pt;
    padding-top: 3em;
    padding-bottom: 4px;
    border-bottom: 1px solid #cac8c0;
    color: #484641;
  }
  table td {
    padding: 0.75em 0.5em;
    text-align: right;
    white-space: nowrap;
  }
  table .summary {
    border-top: 1px solid #cac8c0;
    padding-top: 1em;
    padding-bottom: 0.5em;
  }
}