@import './Colors.scss';

.CartPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px !important;

    > h1 {
        text-align: center;
    }

    > p {
        max-width: 550px;
        margin: auto;
        text-align: center;
        padding-right: 10px;
        padding-left: 10px;
    }

    > p:nth-of-type(1) {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    input[type=text] {
        width: 50px;
    }

    select {
        width: 80px;
    }
}

.CartPage-table {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    width: calc(100% - 20px);

    > .CartPage-tableRow:nth-child(odd) {
        background-color: $rowalternate;
        border-top: 1px solid $rowseparator;
        border-bottom: 1px solid $rowseparator;
    }

    > .CartPage-tableRow:last-child {
        border-bottom: 1px solid $rowseparator;
    }
}

.CartPage-tableRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    height: 85px;

    > *:nth-child(1) {
        flex-grow: 1;
    }

    > span:nth-child(2) {
        flex-grow: 0;
        width: 70px;
        text-align: right;
    }

    > div:nth-child(3) {
        flex-grow: 0;
        width: 100px;
        text-align: right;
    }

    > span:nth-child(4) {
        flex-grow: 0;
        width: 70px;
        text-align: right;
    }
}

.CartPage-productTitle {
    display: flex;
    flex-direction: row;

    > *:first-child {
        margin-right: 10px;
    }

    > div:nth-child(2) > div {
        display: flex;
        flex-direction: row;

        > *:nth-child(1) {
            color: $testoutyellow;
            margin-right: 10px;
            font-size: 12px;
        }

        > *:nth-child(2) {
            color: $graytext;
            font-size: 12px;
        }
    }
}

.CartPage-tableHeader {
    height: 35px;
    border: unset !important;
    border-radius: 5px;
    background-color: $formback !important;
    margin-bottom: 10px;
    @extend .CartPage-tableRow
}

.CartPage-total {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    max-width: 900px;
    width: calc(100% - 20px);
    margin-top: 30px;

    > span:last-of-type {
        font-size: 9pt;
        margin-right: 10px;
        display: inline-block;
        transform: translateY(-2px);
    }

    > span:first-of-type {
        font-size: 16pt;
        font-weight: 700;
    }
}

.CartPage-links {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    align-self: flex-end;
    margin-top: 30px;
    margin-right: 50px;

    > a:first-of-type {
        margin-left: 25px !important;
    }

    > div {
        display: flex;
        flex-direction: row-reverse;

        > * {
            display: inline-block;
            margin-left: 5px !important;
        }
    }
}

.CartPage-cancelInput {
    display: inline-block;
    margin-left: -18px;
    margin-top: 11px;
    transform: translateX(-4px);
}

.CartPage-smallScreenPrice {
    display: none !important;
}

.CartPage-largeScreenPrice {
}

@media(max-width: 650px) {
    .CartPage-tableHeader {
        display: none !important;
    }

    .CartPage-tableRow {
        height: 120px !important;

        > div > img {
            visibility: hidden;
            width: 0px;
            height: 0px;
        }

        input[type=text], img.CartPage-cancelInput {
            transform: translateX(-20px) translateY(18px);
        }

        select, .CartPage-itemTotal {
            transform: translateY(18px);
        }

        .CartPage-productTitle {
            display: flex !important;
            flex-direction: column !important;

            > div:first-of-type {
                overflow: visible;
                white-space: nowrap;
                max-width: 100px;
            }
        }
    }

    .CartPage-tableRow:nth-child(2) {
        border-top: 1px solid $rowseparator !important;
    }

    .CartPage-smallScreenPrice {
        display: block !important;
        text-align: end;
        padding-right: 15px !important;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .CartPage-largeScreenPrice {
        visibility: hidden !important;
        width: 0px !important;
        height: 0px !important;
    }

    .CartPage-links {
        margin-right: 10px !important;
        flex-direction: column-reverse !important;
        align-items: flex-end !important;

        > div {
            margin-bottom: 10px;
        }
    }
}
